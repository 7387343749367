import * as React from "react"
import { graphql } from 'gatsby';

import NotFound from 'pages/404';
import Layout from 'templates/Layout';
import zparse from 'helpers/zparse';
import ReadTime from 'components/lib/ReadTime'
import Link from 'components/Link'

import DataProvider from 'providers/data';

const ReleaseDetail = (props) => {
	const { edges: nodes } = props.data.allBlogPost;
	const posts = nodes.map(({ node }) => node);
	let post = posts[0];

	if (props.providerStatus < 2) return null; // This prevents any flash of content until the API is loaded -- but if the API takes a while to respond the effect would be the page loads slower. You could also use state to reveal content with a transition
	if ((props.providerResponse.data.length > 0 || !!post !== false) && props.providerStatusText === 'LOADED') {
		post = props.providerResponse.data[0];
	}
	if (!!post === false && props.providerStatus >= 2) return <NotFound />;

	const monthNames = ["January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"
	];
	const d = new Date(Date.parse(post.showDate));
	const dateString = `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
	return (
		<Layout>
			<section id="news">
				<div className="grid-container">
					<div className="grid-x grid-margin-x align-center">
						<div className="small-12 medium-8 cell">
							<small><Link to={`/news/`}>&laquo; Back to News</Link></small>
							<h1>{post.title}</h1>
							<div className={`byline`}>
								<ReadTime className={`readTime`} data={post.body} prefix='' suffix='Min Read' />
								<div className={`separator`}>|</div>
								<div className={`date`}>{dateString}</div>
							</div>
							{post.photo && !post.headerImage && <img src={post.photo} alt={post.title} style={{ marginBottom: '1rem' }} />}
							{zparse(post.body || '')}
						</div>
					</div>
				</div>
			</section>
		</Layout>
	)
}

const getData = (props) => <DataProvider liveRefresh={props.params.liveRefresh} type="BlogPost" apiParams={{ permalink: props.params.permalink || props.pageContext.permalink }}><ReleaseDetail {...props} /></DataProvider>
export default getData;

export const query = graphql`
	query ($permalink:String) {
		allContentPage(
			filter: {uri: {eq: "/news/"}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}
		  ) {
			edges {
			  node {
				pageTitle
				meta
				showContactForm
				showNewsletterSignUp
				headlineBody
				headlineTitle
				headlineImage
				headlineType
				hoverboards
			  }
			}
		}
	  allBlogPost(filter: {permalink: {eq: $permalink}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
			id
			title
	        body
	        photo
	      }
	    }
	  }
	}
`